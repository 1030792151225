<template>
  <div class="student">
    <el-form class="search" :inline="true" :model="query">
      <el-form-item label="评价状态:">
        <el-select v-model="query.status" clearable filterable class="select" @change="statusChange">
          <el-option clearable v-for="item in statusOptions" :key="item.code" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop label="日期范围：">
        <el-date-picker class="date" v-model="sdate" type="datetimerange" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange"></el-date-picker>
      </el-form-item>
      <el-form-item label="被评人:" class="input-label">
        <el-input placeholder="输入被评人" clearable class="input" v-model.trim="query.ratabledName"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search" class="button">查询</el-button>
        <el-button class="button" plain type="primary" @click="goBack()">返回</el-button>
      </el-form-item>
    </el-form>
    <!-- 页面主体 -->
    <div class="content">
      <div class="info">
        <el-table :data="list" style="width: 100%" :header-cell-style="{ background: '#eff3ff', color: '#5799f4' }" :row-style="{ background: '#fafafa' }">
          <el-table-column type="index" width="80" label="序号" align="center">
            <!-- <template slot-scope="scope,index">
              <span>{{ index }}</span>
            </template> -->
          </el-table-column>
          <el-table-column prop="ratabledName" label="被评人" align="center"></el-table-column>
          <el-table-column prop="createTime" label="评价时间" align="center"></el-table-column>
          <el-table-column prop="status" label="评价状态" align="center">
            <template slot-scope="scope">
              <el-tag :type="scope.row.status | statusFilter">{{ scope.row.status ? '已评价' : '未评价' }}</el-tag>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="score" label="评价得分" align="center">
          </el-table-column>-->
          <el-table-column prop="caozuo" label="操作" align="center">
            <template slot-scope="scope" class="status code1">
              <el-button @click.native.prevent="deleteRow(scope.row)" type="text" size="medium" :class="{ code1: scope.row.status }">{{ scope.row.status == false ? '进行评价' : '查看详情' }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 页码 -->
      <div class="page-block">
        <el-pagination :page-size="query.limit" :current-page="query.page" layout="total,prev, pager, next, jumper" :total="total" background prev-text="上一页" next-text="下一页" @current-change="handleCurrentChange" v-if="show && pages > 1"></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import NavTop from '@/components/NavTop.vue';
import api from '@/api/question';
// 用于下拉框
const statusOptions = [
  { code: 2, name: '请选择' },
  { code: 0, name: '未评价' },
  { code: 1, name: '已评价' },
];
export default {
  components: {
    NavTop,
  },
  data() {
    return {
      query: {
        status: '',
        ratabledName: '',
        page: 1,
        limit: 20,
        startDate: '',
        endDate: '',
      },
      sdate: '',
      statusOptions,
      paperId: '',
      ratabledId: [],
      list: [], //接口数据
      total: 0,
      pages: 1,
      show: true,
    };
  },
  mounted() {
    this.paperId = this.$route.query.paperId;
    this.fetchData();
  },
  methods: {
    fetchData() {
      var params = {
        paperId: this.paperId,
        status: this.query.status,
        ratabledName: this.query.ratabledName,
        page: this.query.page,
        limit: this.query.limit,
        startDate: this.query.startDate,
        endDate: this.query.endDate,
      };
      api.getAllRataList(params).then(response => {
        if (response.success) {
          this.list = response.result.records;
          this.list.forEach(item => {
            if (item.createTime == null) {
              item.status = false;
            } else {
              item.status = true;
            }
          });
          this.total = response.result.total;
          this.pages = response.result.pages;
          this.show = false;
          this.$nextTick(() => {
            this.show = true;
          })
        }else{
          this.$message({
            message: '请求异常',
            type: 'warning'
          })
        }
      })
    },
    dateChange(val) {
      if (val) {
        this.query.startDate = val[0];
        this.query.endDate = val[1];
        this.query.status = 1;
      } else {
        this.query.startDate = '';
        this.query.endDate = '';
        this.query.status = 2;
      }
    },
    statusChange(val) {
      this.query.status = val;
      if (this.query.status !== 1) {
        this.query.startDate = '';
        this.query.endDate = '';
        this.sdate = '';
      }
    },
    search() {
      this.query.page = '';
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.fetchData();
    },
    deleteRow(e) {
      this.$router.push({
        name: 'qdetail',
        query: {
          paperId: this.paperId,
          ratabledId: e.ratabledId,
          type:e.createTime?'end':'nostart'
        },
      });
    },
    goBack() {
      this.$router.go(-1)
      // this.$router.push({
      //   name: 'MyQuestion',
      // });
    },
  },
  filters: {
    statusFilter(status) {
      const statusMap = { false: 'danger', true: 'success' };
      return statusMap[status];
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/mixin.scss';
.el-button {
  border: none;
}
.student {
  width: 91%;
  background-color: #ffffff;
  min-height: calc(100vh - 264px);
  .search {
    color: hsl(219, 10%, 38%);
    background: #fff;
    padding: 20px 0 0 20px;
    box-sizing: border-box;
    /deep/.el-form-item__label {
      font-size: 14px;
    }
    .select {
      width: 140px;
      border-radius: 6px;
    }
    .date {
      flex: 1;
    }
    .input {
      width: 190px;
      border-radius: 6px;
    }
    .button {
      border-radius: 6px;
    }
    /deep/.el-input__inner {
      font-size: 14px;
    }
    /deep/.el-range-input {
      font-size: 14px;
    }
    // /deep/.el-input__icon {
    //   font-size: 17px;
    // }
    /deep/.el-select-dropdown__item {
      font-size: 14px;
    }
    /deep/.el-button {
      font-size: 14px;
    }
  }
  .content {
    position: relative;
    padding: 0 29px 18px 29px;
    .info {
      @include border();
      border-radius: 6px;
      padding: 21px 14px 14px 14px;
      /deep/.el-table {
        background-color: #fafafa;
        .cell {
          font-size: 16px;
        }
        .el-table__header {
          .cell {
            font-size: 16px;
          }
        }
        .el-button--text {
          font-size: 16px;
        }
        .status {
          color: #4691f3;
          .code0 {
            color: #4691f3;
          }
          .code1 {
            color: #fa4f33;
          }
        }
      }
    }
    .el-tag {
      font-size: 14px;
    }
    /deep/.page-block {
      // position: absolute;
      // bottom: 54px;
      margin: 34px auto 0;
      padding-bottom: 38px;
      display: flex;
      justify-content: center;
      .button {
        height: 32px;
        line-height: 9px;
      }
      .el-button {
        border: none;
      }
    }
    /deep/.el-pagination button,
    .el-pagination span:not([class*='suffix']) {
      width: 76px;
      height: 32px;
      border: 1px solid #dddbdb;
      border-radius: 4px;
      span {
        display: inline-block;
        height: 32px;
        line-height: 32px;
      }
    }
    /deep/.el-pager {
      height: 32px;
      li {
        height: 32px;
        line-height: 32px;
      }
    }
    /deep/.el-pagination__jump {
      height: 32px !important;
      line-height: 32px !important;
    }
  }
}
</style>
